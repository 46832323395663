<template>
  <div>
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"
    />
    <div class="row" v-if="!isDistributor && !isDistributor_khusus">
      <div class="col-md-12">
        <filter-province class="mr-2 mt-2" v-model:value="state.params.provinsi" />
        <filter-distributor
          class="mr-2 mt-2"
          v-model:value="state.params.distributor"
          v-model:provinsi="state.params.provinsi"
        ></filter-distributor>
        <a-date-picker
          v-model:value="state.params.date"
          placeholder="Tanggal"
          style="width: 300px"
          class="mr-2 mt-2"
          format="DD MMMM YYYY"
          :disabled-date="disabledStartDate"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <a-date-picker
          v-if="isDistributor || isDistributor_khusus"
          v-model:value="state.params.date"
          placeholder="Tanggal"
          style="width: 300px"
          class="mr-2 mt-2"
          format="DD MMMM YYYY"
        />
        <a-input-search
          class="mr-2 mt-2"
          v-model:value="state.params.q"
          placeholder="Cari ..."
          style="width: 300px"
        />
        <a-button class="mt-2" type="primary" title="cari" @click="fetchData" :loading="state.isFetching">
          <i class="fa fa-search" aria-hidden="true" v-if="!state.isFetching"></i>
          <span v-else>Memuat Data ...</span>
        </a-button>
      </div>
    </div>
    <div class="row justify-content-end">
      <div class="col-lg-6 col-md-12"></div>
      <div class="col-lg-6 col-md-12 text-right">
        <ASpace>
          <DownloadExcel
            :url="state.endpoint"
            :params="queryParams()"
            title="Download Excel Detail Stok Distributor"
            namefile="laporan-detail-stok-distributor"/>
          <AButton
              type="primary"
              @click="fetchXlsxCurrent"
              :loading="state.isDownload">
            <i class="fa fa-file-excel-o" aria-hidden="true" v-if="!state.isDownload"></i>
            <span v-else>Download ...</span>
          </AButton>
        </ASpace>
      </div>
    </div>

    <div class="table-responsive mt-4">
      <md-table
        :columns="state.columns.current"
        :data-source="state.data"
        size="small"
        row-key="id"
        :pagination="{
          showSizeChanger: true,
          pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
          showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
          total: state.meta.total,
          pageSize: state.meta.per_page,
          current: state.meta.page,
        }"
        :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
        bordered
        @change="handleTableChange"
        :loading="state.isFetching"
      >
        ><template #no="{ index }">
          <span>
            {{ (state.meta.page - 1) * state.meta.per_page + 1 + index }}
          </span>
        </template>
        <template #progress="{ text: bar }">
          <div class="progress">
            <div
              :class="['progress-bar', bar.color]"
              :style="{ width: bar.value + '%' }"
              role="progressbar"
            ></div>
          </div>
        </template>
        <template #value="{ text }">
          <span class="font-weight-bold">{{ text }}</span>
        </template>
        <template #id="{ text }">
          <a href="javascript: void(0);" class="btn btn-sm btn-light">{{ text }}</a>
        </template>
        <template #total="{ text }">
          <span>${{ text }}</span>
        </template>
        <template #tax="{ text }">
          <span>${{ text }}</span>
        </template>
        <template #shipping="{ text }">
          <span>${{ text }}</span>
        </template>
        <template #ribuan="{ text }">
          <span>{{ formatRibuan(text) }} </span>
        </template>
        <template #levelStok="{ text }">
          <span v-if="parseInt(text) >= 100" class="text-custom-red">
            {{ text ? Math.round(text) + '%' : 0 }}
          </span>
          <span v-else-if="parseInt(text) < 100 && parseInt(text) >= 80" class="text-custom-orange">
            {{ text ? Math.round(text) + '%' : 0 }}
          </span>
          <span v-else class="text-custom-dark">
            {{ text ? Math.round(text) + '%' : 0 }}
          </span>
        </template>
        <template #action="{record}">
          <span>
            <a-tooltip title="Detail Stok Distributor">
              <a href="javascript: void(0);" class="btn btn-sm btn-success" @click="detail(record)">
                <i class="fe fe-info fa-lg" />&nbsp;Detail
              </a>
            </a-tooltip>
          </span>
        </template>
      </md-table>
    </div>

    <a-modal
      v-model:visible="modalVisibleDetail"
      title="Detail Stok Distributor"
      :destroy-on-close="true"
      width="1000px"
      :mask-closable="false"
      @ok="modalVisibleDetail = false"
    >
      <template #footer>
        <a-button key="back" type="primary" @click="modalVisibleDetail = false">Close</a-button>
      </template>
      <div class="table-responsive">
        <md-table
          :columns="state.columns.detail"
          :data-source="state.datadetails"
          size="small"
          row-key="id"
          :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
          bordered
          :pagination="{
              showSizeChanger: true,
              pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
              showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
              total: state.metaDetail.total,
              pageSize: state.metaDetail.per_page,
              current: state.metaDetail.page,
          }"
          @change="handleTableChangeDetail"
          :loading="state.isFetchingDetail"
        >
        </md-table>
      </div>
    </a-modal>
    
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  reactive,
  onMounted,
  toRefs,
} from 'vue'
import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'
import FilterProvince from '@/components/filter/FilterProvince'
import FilterDistributor from '@/components/filter/FilterDistributorOI'
import qs from 'qs'
import { useDisabledDate } from '@/composables/DisabledDate'
import useUserInfo from '@/composables/useUserInfo'
import moment from 'moment'
import store from '@/store'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import {
  updateUrlWithQuery,
  extractQueryParams,
} from '@/helpers/globalmixin'

export default defineComponent({
  name: 'MFilter',
  components: {
    FilterProvince,
    FilterDistributor,
    DownloadExcel,
  },
  setup() {
    const errorMessage = ref(null)
    const { isDistributor, vendor_id, isDistributor_khusus } = useUserInfo()

    const state = reactive({
      columns: {
        current: [
          {
            title: 'No',
            slots: { customRender: 'no' },
          },
          {
            title: 'Kode Distributor SI',
            dataIndex: 'vendor_si',
          },
          {
            title: 'Kode Distributor SBI',
            dataIndex: 'vendor_sbi',
          },
          {
            title: 'Nama Distributor',
            dataIndex: 'distributor',
          },
          {
            title: 'Kode Gudang SI',
            dataIndex: 'gudang_si',
          },
          {
            title: 'Kode Gudang SBI',
            dataIndex: 'gudang_sbi',
          },
          //  {
          //   title: 'Kode Distrik',
          //   dataIndex: 'kode_distrik',
          // },
          // {
          //   title: 'Nama Distrik',
          //   dataIndex: 'nama_distrik',
          // },
          {
            title: 'Tanggal Stok',
            dataIndex: 'tanggal',
          },
          {
            title: 'Nama Gudang',
            dataIndex: 'gudang',
          },
          {
            title: 'Alamat Gudang',
            dataIndex: 'address',
          },
          {
            title: 'Kapasitas Gudang',
            dataIndex: 'kapasitas',
          },

          {
            title: 'Kemampuan Bongkar Gudang',
            dataIndex: 'kapasitas_bongkar',
          },
          {
            title: 'Volume Stok (ZAK)',
            dataIndex: 'stok',
          },
          {
            title: 'Level Stok',
            dataIndex: 'level_stock',
            slots: { customRender: 'levelStok' },
          },
          {
            title: 'Action',
            slots: { customRender: 'action' },
          },
        ],
        detail: [
          {
            title: 'Kode Gudang SI',
            dataIndex: 'gudang_si',
          },
          {
            title: 'Kode Gudang SBI',
            dataIndex: 'gudang_sbi',
          },
          {
            title: 'Tanggal Stok',
            dataIndex: 'tanggal',
          },
          {
            title: 'Nama Gudang',
            dataIndex: 'gudang',
          },
          {
            title: 'Nama Brand',
            dataIndex: 'brand',
          },
          {
            title: 'Kode Produk',
            dataIndex: 'kode_produk',
          },
          {
            title: 'Nama Produk',
            dataIndex: 'nama_produk',
          },
          {
            title: 'Volume Stok (ZAK)',
            dataIndex: 'stok',
          },
        ],
      },
      endpoint: '/api/report/distributor',
      data: [],
      datadetails: [],
      isFetching: false,
      isFetchingDetail: false,
      isDownload: false,
      params: extractQueryParams({
        q: '',
        provinsi: [],
        distributor: [],
        date: moment(new Date()).add(-1, 'days'),
        page: 1,
        'per-page': 10,
      }),
      meta: {
        per_page: 10,
        page: 1,
        total: 0,
      },
      metaDetail: {
        per_page: 10,
        page: 1,
        total: 0,
      },
      paramsDetail: {
        gudang: null,
        page: 1,
        'per-page': 10,
      },
    })

    const handleTableChange = (pag, filters, sorter) => {
      state.params.page = pag.current
      state.params['per-page'] = pag.pageSize
      
      fetchData()
    }

    const handleTableChangeDetail = (pag, filters, sorter) => {
      state.paramsDetail.page = pag.current
      state.paramsDetail['per-page'] = pag.pageSize
      
      fetchDetail()
    }

    const queryParams = () => {
      let params = state.params

      if (state.params.date) {
        state.params.date = moment(state.params.date).format('YYYY-MM-DD')
      }

      return updateUrlWithQuery(params)
    }

    const fetchData = () => {
      state.isFetching = true
      apiClient
        .get(state.endpoint, {
          params: queryParams(),
        })
        .then(({ data }) => {
          const { items, _meta } = data

          state.data = items
          state.meta.page = _meta.currentPage
          state.meta.per_page = _meta.perPage
          state.meta.total = _meta.totalCount
        })
        .finally(() => {
          state.isFetching = false
        })
    }

    const fetchXlsxCurrent = () => {
      state.isDownload = true
      apiClient
        .get(state.endpoint, {
          params: {
            ...queryParams(),
            _export2: 'xls',
            _columns: state.columns.current
              .filter(c => c.dataIndex !== null && c.dataIndex !== undefined)
              .map(({ dataIndex: key, title }) => ({ key, title })),
          },
          paramsSerializer: function(params) {
            return qs.stringify({ ...params, ok: 'hmm' }, { arrayFormat: 'indices' })
          },
          responseType: 'blob',
          headers: {},
        })
        .then(response => {
          if (response === undefined) {
            errorMessage.value = `Kode error 500, No response from server`
            return
          }
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            `laporan-stok-distributor_${moment().format('YYYY-MM-DD_HHmm')}.xlsx`,
          )
          document.body.appendChild(link)
          link.click()
        })
        .catch(async error => {
          errorMessage.value = null
          if (error.response) {
            const { status, statusText } = error.response
            const message = JSON.parse(await error.response.data.text()).message
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
        .finally(() => {
          state.isDownload = false
        })
    }

    const fetchDetail = () => {
      state.datadetails = []
      state.isFetchingDetail = true
      apiClient
        .get('api/report/detail-distributor', {
          params: {
            ...queryParams(),
            ...state.paramsDetail,
          },
        })
        .then(({ data }) => {
          const { items, _meta } = data
          state.datadetails = items
          state.metaDetail.page = _meta.currentPage
          state.metaDetail.per_page = _meta.perPage
          state.metaDetail.total = _meta.totalCount
        })
        .finally(() => {
          state.isFetchingDetail = false
        })
    }

    const modalVisibleDetail = ref(false)
    const detail = (record) => {
      modalVisibleDetail.value = true
      state.paramsDetail.gudang = record.gudang_param
      fetchDetail()
    }

    onMounted(() => {
      // validation roles default params
      if (isDistributor.value || isDistributor_khusus.value) {
        state.params.distributor.push(vendor_id.value)
      }
      
      fetchData()
    })

    return {
      handleTableChange,
      state,
      fetchXlsxCurrent,
      queryParams,
      fetchData,
      fetchDetail,
      errorMessage,
      modalVisibleDetail,
      detail,
      isDistributor,
      isDistributor_khusus,
      handleTableChangeDetail,
    }
  },
})
</script>

<style>
.text-custom-red {
  color: #ff0000;
}

.text-custom-orange {
  color: #ffa500;
}

.text-custom-yellow {
  color: #eedf11;
}

.text-custom-dark {
  color: #000000;
}

.text-custom-green {
  color: #008000;
}
</style>
